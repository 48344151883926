import { styled } from '@mui/material';
import {
  SIGNOUT,
  PROFILE,
  DASHBOARD,
  REPORTS,
  adminInvoicesPage,
  adminUsersPage,
  adminEmployeesPage,
  recruitmentQuestionnairesManagePage,
  adminSubscriptionPage,
  adminSearchVendorsPage,
  adminMyVendorsPage,
  notificationsPage,
  helpAndFeedbackPage,
  COMPANY_PROFILE,
  SETTINGS,
} from './routes';
import {
  BusinessCenterOutlined,
  DashboardRounded,
  Diversity3Rounded,
  Groups2Outlined,
  LogoutOutlined,
  SettingsRounded,
} from '@mui/icons-material';

export const APP_BAR_HEIGHT = 50;
export const APP_LIST_VIEW = 'list';
export const APP_TILE_VIEW = 'tile';
export const APP_DEFAULT_VIEW = APP_LIST_VIEW;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 240;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 0;
export const DEFAULT_PAGINATION_COUNT = 10;

export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';

export const DRAWER_MENU = [
  {
    id: 1,
    title: 'Dashboard',
    url: DASHBOARD,
    icon: DashboardRounded,
  },
  {
    id: 2,
    title: 'Manage Relationship',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'My vendors',
        url: adminMyVendorsPage(),
      },
      {
        id: 2,
        title: 'Search Vendors',
        url: adminSearchVendorsPage(),
      },
    ],
  },

  {
    id: 3,
    title: 'Recruitment Questionnaires',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Manage Questionnaires',
        url: recruitmentQuestionnairesManagePage(),
      },
    ],
  },

  {
    id: 4,
    title: 'Invoices',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Manage Invoices',
        url: adminInvoicesPage(),
      },
    ],
  },

  {
    id: 5,
    title: 'Manage Employees',
    icon: BusinessCenterOutlined,
    url: adminEmployeesPage(),
  },

  {
    id: 6,
    title: 'Manage Subscription',
    icon: BusinessCenterOutlined,
    url: adminSubscriptionPage(),
  },
  {
    id: 7,
    title: 'Manage Users',
    icon: BusinessCenterOutlined,
    url: adminUsersPage(),
  },
  {
    id: 8,
    title: 'Company Profile',
    icon: BusinessCenterOutlined,
    url: COMPANY_PROFILE,
  },
  {
    id: 9,
    title: 'Profile',
    url: PROFILE,
    icon: Diversity3Rounded,
  },
  {
    id: 10,
    title: 'Notifications',
    url: notificationsPage(),
    icon: Diversity3Rounded,
  },

  {
    id: 11,
    title: 'Help and feedback',
    url: helpAndFeedbackPage(),
    icon: Groups2Outlined,
  },
  {
    id: 12,
    title: 'Settings',
    url: SETTINGS,
    icon: SettingsRounded,
  },
  {
    id: 13,
    title: 'Sign Out',
    url: SIGNOUT,
    icon: LogoutOutlined,
  },
];

export const tableHeaderStyles = {
  fontSize: 'sm',
  fontFamily: 'Raleway',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 'medium',
  flex: 1,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
};

export const NOTIFICATION_TYPE = {
  JOBS: 'JOBS',
  RESOURCES: 'RESOURCES',
  INTERVIEWS: 'INTERVIEWS',
  VENDORS: 'VENDORS',
};

export const paginationState = {
  previous: null,
  next: null,
  noOfPageItems: DEFAULT_PAGINATION_COUNT,
  page: DEFAULT_PAGINATION_PAGE_NO,
  total: DEFAULT_PAGINATION_COUNT * (DEFAULT_PAGINATION_PAGE_NO + 1),
  pageCount: DEFAULT_PAGINATION_PAGE_NO + 1,
  hasMore: false,
  loading: true,
  data: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const DEFAULT_LANGUAGE = 'ja';

export const LANGUAGES = [
  {
    text: 'Japanese',
    value: 'ja',
  },
  {
    text: 'English',
    value: 'en',
  },
];

export const DEFAULT_TIMEZONE = 'GMT+09:00';

export const TIMEZONES = [
  {
    value: 'GMT-12:00',
    text: 'Etc/GMT-12',
  },
  {
    value: 'GMT-11:00',
    text: 'Etc/GMT-11',
  },
  {
    value: 'GMT-11:00',
    text: 'Pacific/Midway',
  },
  {
    value: 'GMT-10:00',
    text: 'America/Adak',
  },
  {
    value: 'GMT-09:00',
    text: 'America/Anchorage',
  },
  {
    value: 'GMT-09:00',
    text: 'Pacific/Gambier',
  },
  {
    value: 'GMT-08:00',
    text: 'America/Dawson_Creek',
  },
  {
    value: 'GMT-08:00',
    text: 'America/Ensenada',
  },
  {
    value: 'GMT-08:00',
    text: 'America/Los_Angeles',
  },
  {
    value: 'GMT-07:00',
    text: 'America/Chihuahua',
  },
  {
    value: 'GMT-07:00',
    text: 'America/Denver',
  },
  {
    value: 'GMT-06:00',
    text: 'America/Belize',
  },
  {
    value: 'GMT-06:00',
    text: 'America/Cancun',
  },
  {
    value: 'GMT-06:00',
    text: 'America/Chicago',
  },
  {
    value: 'GMT-06:00',
    text: 'Chile/EasterIsland',
  },
  {
    value: 'GMT-05:00',
    text: 'America/Bogota',
  },
  {
    value: 'GMT-05:00',
    text: 'America/Havana',
  },
  {
    value: 'GMT-05:00',
    text: 'America/New_York',
  },
  {
    value: 'GMT-04:30',
    text: 'America/Caracas',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Campo_Grande',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Glace_Bay',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Goose_Bay',
  },
  {
    value: 'GMT-04:00',
    text: 'America/Santiago',
  },
  {
    value: 'GMT-04:00',
    text: 'America/La_Paz',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Argentina/Buenos_Aires',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Montevideo',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Araguaina',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Godthab',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Miquelon',
  },
  {
    value: 'GMT-03:00',
    text: 'America/Sao_Paulo',
  },
  {
    value: 'GMT-03:30',
    text: 'America/St_Johns',
  },
  {
    value: 'GMT-02:00',
    text: 'America/Noronha',
  },
  {
    value: 'GMT-01:00',
    text: 'Atlantic/Cape_Verde',
  },
  {
    value: 'GMT',
    text: 'Europe/Belfast',
  },
  {
    value: 'GMT',
    text: 'Africa/Abidjan',
  },
  {
    value: 'GMT',
    text: 'Europe/Dublin',
  },
  {
    value: 'GMT',
    text: 'Europe/Lisbon',
  },
  {
    value: 'GMT',
    text: 'Europe/London',
  },
  {
    value: 'UTC',
    text: 'UTC',
  },
  {
    value: 'GMT+01:00',
    text: 'Africa/Algiers',
  },
  {
    value: 'GMT+01:00',
    text: 'Africa/Windhoek',
  },
  {
    value: 'GMT+01:00',
    text: 'Atlantic/Azores',
  },
  {
    value: 'GMT+01:00',
    text: 'Atlantic/Stanley',
  },
  {
    value: 'GMT+01:00',
    text: 'Europe/Amsterdam',
  },
  {
    value: 'GMT+01:00',
    text: 'Europe/Belgrade',
  },
  {
    value: 'GMT+01:00',
    text: 'Europe/Brussels',
  },
  {
    value: 'GMT+02:00',
    text: 'Africa/Cairo',
  },
  {
    value: 'GMT+02:00',
    text: 'Africa/Blantyre',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Beirut',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Damascus',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Gaza',
  },
  {
    value: 'GMT+02:00',
    text: 'Asia/Jerusalem',
  },
  {
    value: 'GMT+03:00',
    text: 'Africa/Addis_Ababa',
  },
  {
    value: 'GMT+03:00',
    text: 'Asia/Riyadh89',
  },
  {
    value: 'GMT+03:00',
    text: 'Europe/Minsk',
  },
  {
    value: 'GMT+03:30',
    text: 'Asia/Tehran',
  },
  {
    value: 'GMT+04:00',
    text: 'Asia/Dubai',
  },
  {
    value: 'GMT+04:00',
    text: 'Asia/Yerevan',
  },
  {
    value: 'GMT+04:00',
    text: 'Europe/Moscow',
  },
  {
    value: 'GMT+04:30',
    text: 'Asia/Kabul',
  },
  {
    value: 'GMT+05:00',
    text: 'Asia/Tashkent',
  },
  {
    value: 'GMT+05:30',
    text: 'Asia/Kolkata',
  },
  {
    value: 'GMT+05:45',
    text: 'Asia/Katmandu',
  },
  {
    value: 'GMT+06:00',
    text: 'Asia/Dhaka',
  },
  {
    value: 'GMT+06:00',
    text: 'Asia/Yekaterinburg',
  },
  {
    value: 'GMT+06:30',
    text: 'Asia/Rangoon',
  },
  {
    value: 'GMT+07:00',
    text: 'Asia/Bangkok',
  },
  {
    value: 'GMT+07:00',
    text: 'Asia/Novosibirsk',
  },
  {
    value: 'GMT+08:00',
    text: 'Etc/GMT+8',
  },
  {
    value: 'GMT+08:00',
    text: 'Asia/Hong_Kong',
  },
  {
    value: 'GMT+08:00',
    text: 'Asia/Krasnoyarsk',
  },
  {
    value: 'GMT+08:00',
    text: 'Australia/Perth',
  },
  {
    value: 'GMT+08:45',
    text: 'Australia/Eucla',
  },
  {
    value: 'GMT+09:00',
    text: 'Asia/Irkutsk',
  },
  {
    value: 'GMT+09:00',
    text: 'Asia/Seoul',
  },
  {
    value: 'GMT+09:00',
    text: 'Asia/Tokyo',
  },
  {
    value: 'GMT+09:30',
    text: 'Australia/Adelaide',
  },
  {
    value: 'GMT+09:30',
    text: 'Australia/Darwin',
  },
  {
    value: 'GMT+09:30',
    text: 'Pacific/Marquesas',
  },
  {
    value: 'GMT+10:00',
    text: 'Etc/GMT+10',
  },
  {
    value: 'GMT+10:00',
    text: 'Australia/Brisbane',
  },
  {
    value: 'GMT+10:00',
    text: 'Australia/Hobart',
  },
  {
    value: 'GMT+10:00',
    text: 'Asia/Yakutsk',
  },
  {
    value: 'GMT+10:30',
    text: 'Australia/Lord_Howe',
  },
  {
    value: 'GMT+11:00',
    text: 'Asia/Vladivostok',
  },
  {
    value: 'GMT+11:30',
    text: 'Pacific/Norfolk',
  },
  {
    value: 'GMT+12:00',
    text: 'Etc/GMT+12',
  },
  {
    value: 'GMT+12:00',
    text: 'Asia/Anadyr',
  },
  {
    value: 'GMT+12:00',
    text: 'Asia/Magadan',
  },
  {
    value: 'GMT+12:00',
    text: 'Pacific/Auckland',
  },
  {
    value: 'GMT+12:45',
    text: 'Pacific/Chatham',
  },
  {
    value: 'GMT+13:00',
    text: 'Pacific/Tongatapu',
  },
  {
    value: 'GMT+14:00',
    text: 'Pacific/Kiritimati',
  },
];
export const FILTER_OPTIONS = {
  LOCATION: 'location',
  JOB_TITLE: 'jobTitle',

  RECOMMENDED_DATE: 'recommendedDate',
  REQUESTED_DATE: 'requestedDate',
  CREATED_DATE: 'createdDate',
  UPDATED_DATE: 'updatedDate',
  INTERVIEWED_DATE: 'interviewedDate',
  DENIED_DATE: 'deniedDate',
  APPLIED_DATE: 'appliedDate',
  INVITED_DATE: 'invitedDate',
  START_DATE: 'startDate',
  REGISTERED_DATE: 'registeredDate',
  SAVED_DATE: 'savedDate',

  OFFER_STATUS: 'offerStatus',
  INTERVIEW_RESULT: 'interviewResult',
  INVITE_STATUS: 'inviteStatus',
  INTERVIEW_STATUS: 'interviewStatus',

  DEADLINE: 'deadline',
  FACULTY: 'faculty',
  STATUS: 'isActive',
  INDUSTRY: 'industry',
  STUDENT: 'student',
  DENIED_SEMINARS: 'deniedSeminars',

  SEMINAR_TITLE: 'title',
  SEMINAR_STYLE: 'type',

  INTERNSHIP_STYLE: 'style',

  HANDSHAKE_ACC_DATE: 'handshakeAcceptedDate',
  VENDOR_NAME: 'vendorName',
};
