export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;
export const UTILITY_API_ENDPOINT =
  process.env.REACT_APP_UTILITIES_API_ENDPOINT;

export const COMPANY_STUDENT_ENDPOINT =
  process.env.REACT_APP_COMPANY_STUDENT_API_ENDPOINT;

export const baseEndpoint = api => API_ENDPOINT + api;
export const companyStudentEndpoint = api => COMPANY_STUDENT_ENDPOINT + api;

export const authBaseEndpoint = api => AUTH_API_ENDPOINT + api;
export const commonUtilitiesEndpoint = api => UTILITY_API_ENDPOINT + api;

// Subcriptions Endpoints
export const getSubscriptionPlansApi = (params = '') =>
  baseEndpoint('/plans/all?') + encodeURI(params);

export const getSubscriptionActivePlanApi = (params = '') =>
  baseEndpoint('/subscribed-plan/all?') + encodeURI(params);

export const downloadSubscriptionInvoiceApi = (params = '') =>
  baseEndpoint('/subscribed-plan/all');

// Employees Endpoints
export const getEmployeesApi = (params = '') =>
  baseEndpoint('/employee/all?') + encodeURI(params);

export const addEmployeeApi = (params = '') =>
  baseEndpoint('/employee/add?') + encodeURI(params);

export const editEmployeeApi = (id = '') =>
  baseEndpoint('/employee/edit/') + id;

export const deleteEmployeeApi = (id = '') =>
  baseEndpoint('/employee/delete/') + id;

// Jobs Endpoints
export const getJobsApi = (params = '') =>
  baseEndpoint('/jobs/all?') + encodeURI(params);

export const addJobApi = (params = '') =>
  baseEndpoint('/jobs/add?') + encodeURI(params);

export const editJobApi = (id = '') => baseEndpoint('/jobs/edit/') + id;

export const deleteJobApi = (id = '') => baseEndpoint('/jobs/delete/') + id;

// Users Endpoint
export const getUsersApi = (params = '') =>
  baseEndpoint('/get-all-user?') + encodeURI(params);

export const addUsersApi = (params = '') =>
  baseEndpoint('/create-user?') + encodeURI(params);

export const editUsersApi = (id = '') => baseEndpoint('/edit-user/') + id;

export const deleteUsersApi = (id = '') => baseEndpoint('/delete-user/') + id;

export const getAllSubscriptionPlansApi = () =>
  baseEndpoint(
    '/subscriptionplan/getAll?page=0&count=10&sort=ASC&field=subscriptionplanid'
  );

export const createSubscriptionPlanApi = () =>
  baseEndpoint('/subscriptionplan/createsubscriptionplan');

export const getAllApplicationsApi = () =>
  baseEndpoint('/subscriptionplan/getallapplications');

export const jobsApi = () => baseEndpoint('/companyData/jobs');

export const companyDetailsApi = (id = 16) =>
  commonUtilitiesEndpoint('/company/profile/' + id);

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('/auth/signin');
export const registerUserApi = () => authBaseEndpoint('/auth/signup');
export const getUserApi = id => authBaseEndpoint(`/get-user/${id}`);
export const getUserCompanyApi = userEmail =>
  companyStudentEndpoint('/user/details/' + userEmail);

// Onboarding Apis
export const updateUserProfileApi = () =>
  baseEndpoint('/admin/create-user-profile');

export const createCompanyProfileApi = () =>
  baseEndpoint('/create-company-profile');
export const updateCompanyProfileApi = (id = 16) =>
  commonUtilitiesEndpoint(`/company/profile/${id}`);

//Notification Endpoint
export const getNotificationsApi = (params = '') =>
  baseEndpoint(`/notification`) + params;
export const deleteNotificationApi = univId =>
  baseEndpoint(`/notification/clear/${univId}`);

//Help and Feedback Endpoints
export const createHelpRequestApi = () => baseEndpoint('/request-help');
export const createFeedbackRequestApi = () => baseEndpoint('/submit-feedback');

// Vendors endpoint
export const vendorListApi = (params = '') =>
  commonUtilitiesEndpoint('/accepted/vendor/list' + params);
export const searchVendorListApi = (params = '') =>
  commonUtilitiesEndpoint('/vendor/nohandshake/list' + params);

export const createHandShakeApi = (companyId = '', vendorId = '') =>
  commonUtilitiesEndpoint(`/company/${companyId}/vendor/${vendorId}/handshake`);
