export const HOME = '/company';
export const STUDENT_HOME = '/company';
export const COMPANY_MANAGE_SUBSCRIPTION_PAGE = '/company/subscriptions';
export const COMPANY_MANAGE_USER_PAGE = '/company/user';
export const CONFIRM_PAYMENT_PAGE = '/company/subscriptions/confirm-payment';
export const MANAGE_EMPLOYEES = '/company/employees';
export const MANAGE_STUDENTS = '/company/students';
export const REPORTS = '/company/reports';
export const PROFILE = '/company/profile';
export const COMPANY_PROFILE = '/company/company-profile';
export const EDIT_CORPORATE_INFORMATION = '/company/corporate-info';
export const EDIT_COMPANY_PROFILE = '/company/company-profile/edit';
export const HELP_AND_FEEDBACK = '/company/help-and-feedback';
export const ABOUT = '/company/about';
export const SETTINGS = '/company/settings';
export const DASHBOARD = '/company/dashboard';

export const APP = '/app';
export const REGISTER = '/app/register';
export const CREATE_ACCOUNT_PASSWORD = '/app/account-password';
export const LOGIN = '/app/login';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';
export const SIGNOUT = '/app/signout';
export const LOGIN_CALLBACK = '/login/callback';

export const ONBOARDING = '/onboarding';
export const CREATE_YOUR_PROFILE = '/onboarding/create-profile';

export const homepage = () => '/company';
export const studentHomePage = () => '/company';
export const adminPage = () => '/admin';
export const adminSearchResoucePage = () => adminPage() + '/search-resources';
export const adminSearchResouceVendorEmployeesPage = (vendorId = ':id') =>
  adminSearchResoucePage() + `/${vendorId}/employees`;
export const adminSubscriptionPage = () => adminPage() + '/subscriptions';
export const adminUsersPage = () => adminPage() + '/user';
export const adminPaymentPage = () =>
  adminPage() + '/subscriptions/confirm-payment';
export const adminMyVendorsPage = () => adminPage() + '/vendors/my';
export const adminMyVendorsNDAPage = () => adminPage() + '/vendors/my?tab=1';
export const uploadVendorDocumentsPage = (id = ':id') =>
  adminPage() + `/vendors/upload-document/${id}`;
export const uploadedVendorsDocumentPage = (id = ':id') =>
  adminPage() + `/vendors/uploaded-document/${id}`;

export const adminSearchVendorsPage = () => adminPage() + '/vendors/search';
export const adminEmployeesPage = () => adminPage() + '/employees';
export const adminInvoicesPage = () => adminPage() + '/invoices';
export const uploadInvoiceDocumentsPage = (id = ':id') =>
  adminPage() + `/invoices/upload-document/${id}`;
export const uploadedInvoicesDocumentPage = (id = ':id') =>
  adminPage() + `/invoices/uploaded-document/${id}`;
export const adminQuestionnairesPage = () => adminPage() + '/questionnaires';
export const manageStudents = () => '/company/students';
export const reportsPage = () => '/company/reports';
export const profilePage = () => '/company/profile';
export const companyProfilePage = () => '/company/company-profile';
export const editCorporateInfoPage = () => '/company/corporate-info';
export const editCompanyProfilePage = () => '/company/company-profile/edit';
export const aboutPage = () => '/company/about';
export const settingsPage = () => '/company/settings';
export const dashboardPage = () => '/company/dashboard';
export const app = () => '/app';
export const loginPage = () => '/app/login';
export const signOutPage = () => '/app/signout';
export const loginCallbackPage = () => '/login/callback';
export const oboardingPage = () => '/onboarding';

export const jobsPage = () => '/jobs';
export const jobsCreateJobsPage = () => jobsPage() + '/create-jobs';
export const jobsSearchResourcePage = () => jobsPage() + '/search-resource';
export const jobsAppliedJobsPage = () => jobsPage() + '/applied-jobs';
export const jobsInterviewPage = () => jobsPage() + '/interview';
export const jobsOfferPage = () => jobsPage() + '/job-offers';

export const relationshipPage = () => '/relationship';
export const relationshipMyVendorsPage = () =>
  relationshipPage() + '/my-vendor';
export const relationshipSearchVendorsPage = () =>
  relationshipPage() + '/search-vendors';

export const recruitmentQuestionnairesPage = () =>
  '/recruitment-questionnaires';
export const recruitmentQuestionnairesManagePage = () =>
  recruitmentQuestionnairesPage() + '/manage';
export const notificationsPage = () => homepage() + '/notifications';
export const helpAndFeedbackPage = () => '/company/help-and-feedback';
export const helpDetailsPage = (id = ':id') =>
  homepage() + `/${id}/questions-answers`;
