import paymentMasterIcon from 'assets/images/master.png';
import paymentVisaIcon from 'assets/images/visa.png';
import paymentStripeIcon from 'assets/images/stripe.png';
import paymentPaypalIcon from 'assets/images/paypal.png';
import itMapStudent from 'assets/images/it-map-student.png';
import itMapMidCareer from 'assets/images/it-map-mid-career.png';
import itMapConsultant from 'assets/images/it-map-consultant.png';
import emptyViewJobs from 'assets/images/empty-view-jobs.png';
import emptyPublishedJobs from 'assets/images/empty-published-jobs.png';
import emptyBasedOnJobs from 'assets/images/empty-based-on-jobs.png';
import emptyMyVendorsAccepted from 'assets/images/empty-my-vendors-accepted.png';
import recentQuestion from 'assets/images/recent-question.png';

export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/logo.svg';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const DEFAULT_IMAGE = '/company-logo.png';
export const PROFILE_BANNER_IMAGE = '/banner-pattern.jpeg';

// Payment icons
export const PAYMENT_STRIPE_ICON = paymentStripeIcon;
export const PAYMENT_VISA_ICON = paymentVisaIcon;
export const PAYMENT_MASTER_ICON = paymentMasterIcon;
export const PAYMENT_PAYPAL_ICON = paymentPaypalIcon;
export const UPLOAD_IMAGE = '/upload-icon.svg';

export const itMapStudentIcon = itMapStudent;
export const itMapMidCareerIcon = itMapMidCareer;
export const itMapConsultantIcon = itMapConsultant;
export const emptyViewJobsIcon = emptyViewJobs;
export const emptyPublishedJobsIcon = emptyPublishedJobs;
export const emptyBasedOnJobsIcon = emptyBasedOnJobs;
export const emptyMyVendorsAcceptedIcon = emptyMyVendorsAccepted;
export const recentQuestionIcon = recentQuestion;

//Help Tab Icons
export const HELP_CREATE_ACCOUNT_ICON = '/images/account.png';
export const HELP_UNIVERSITY_INFO_ICON = '/images/university.png';
export const HELP_LOGIN_ICON = '/images/login.png';
export const HELP_HANDSHAKE_ICON = '/images/handshake.png';
export const HELP_JOBS_ICON = '/images/jobs.png';
export const HELP_INTERNSHIP_ICON = '/images/internship.png';
export const HELP_SEMNAR_ICON = '/images/seminar.png';
export const HELP_OTHERS_ICON = '/images/others.png';
export const HELP_SHCOLARSHIP_AND_DONATION_ICON = '/images/scholarship.png';
export const HELP_MANAGE_USER_ICON = '/images/user.png';
export const ADDITIONAL_INFO_IMG = '/images/additional-info-cmp-banner.jpg';

export const DELETE_ICON_SVG = '/images/DeleteIcon.svg';
export const UPLOAD_PROFILE_ICON = '/images/uploadProfile.png';
