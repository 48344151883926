import moment from 'moment';
import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  addJobApi,
  deleteJobApi,
  editJobApi,
  getJobsApi,
} from 'utils/constants/apis';
import {
  DEFAULT_ERROR_MESSAGE,
  paginationState,
  DEFAULT_PAGINATION_COUNT,
} from 'utils/constants/app';
import axiosInstance from 'utils/helpers/axios';
import qs from 'query-string';
import jobsData from 'dummy/jobsTable.json';

const JobsContext = createContext({
  loading: false,
  jobs: paginationState,
  getJobs: noop,
  addJob: noop,
  editJob: noop,
  deleteJob: noop,
});

export const useJobs = () => useContext(JobsContext);

export const JobsProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState(paginationState);
  const { enqueueSnackbar } = useSnackbar();

  const getJobs = (payload, onSuccess = noop, onError = noop) => {
    const { page } = payload;
    const params = qs.stringify({
      count: DEFAULT_PAGINATION_COUNT,
      page,
    });
    setLoading(true);
    axiosInstance
      .get(getJobsApi(params))
      .then(data => {
        const result = data.data || [];
        setJobs(o => ({
          ...o,
          ...data,
          data: [...result],
        }));

        onSuccess(data);
      })
      .catch(err => {
        // enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
        //   variant: 'error',
        // });
        // onError(err);
      })
      .finally(() => {
        setJobs(o => ({
          ...o,
          data: [...jobsData],
        }));
        setLoading(false);
      });
  };

  const addJob = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(addJobApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Added employee successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editJob = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { id, ...rest } = payload;
    axiosInstance
      .put(editJobApi(id), rest)
      .then(data => {
        enqueueSnackbar(t('Updated employee successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteJob = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { id } = payload;
    axiosInstance
      .delete(deleteJobApi(id))
      .then(data => {
        enqueueSnackbar(t('Deleted employee successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contextValue = {
    loading,
    jobs,
    getJobs,
    addJob,
    editJob,
    deleteJob,
  };

  return (
    <JobsContext.Provider value={contextValue}>{children}</JobsContext.Provider>
  );
};

export default JobsProvider;
