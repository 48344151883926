import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { noop } from 'utils/helpers';
import { loginRequest } from 'utils/msalConfig';
import {
  LS_ACCESS_TOKEN,
  LS_ID_TOKEN,
  LS_USER_ID,
} from 'utils/constants/localStorage';
import axiosInstance from 'utils/helpers/axios';
import { getUserCompanyApi } from 'utils/constants/apis';
import { useUserData } from 'hooks/useUserData';

const AuthContext = createContext({
  isAuthenticated: false,
  inProgress: false,
  idToken: null,
  accessToken: null,
  login: noop,
  logout: noop,
  fetchTokensIfAuthenticated: noop,
  user: null,
  company: null,
  loading: false,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const idToken = localStorage.getItem(LS_ID_TOKEN);
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  const [user, setUser] = useState();
  const { getUserCompanyDetails, loading } = useUserData();
  // Define the token fetching function inside the AuthProvider

  const fetchTokensIfAuthenticated = async () => {
    if (account && inProgress === InteractionStatus.None) {
      const request = {
        ...loginRequest,
        account: account,
      };

      try {
        const response = await instance.acquireTokenSilent(request);
        const idToken = response?.idToken;
        const accessToken = response?.accessToken;
        return { idToken, accessToken };
      } catch (error) {
        // Handle token acquisition error
        console.error('Token acquisition error:', error);
        return null;
      }
    }

    return null;
  };

  useEffect(() => {
    console.log('Calling useAuth useffect:', inProgress, isAuthenticated);
    if (account && inProgress === InteractionStatus.None && isAuthenticated) {
      console.log('Calling useAuth if block in useeffect:');

      // Call the token fetching function
      fetchTokensIfAuthenticated().then(tokens => {
        if (tokens) {
          // Tokens were successfully fetched
          const { idToken, accessToken } = tokens;
          console.log('Tokens acquired:', tokens);
          localStorage.setItem(LS_ID_TOKEN, idToken);
          localStorage.setItem(LS_ACCESS_TOKEN, accessToken);
        }
      });

      getUserCompanyDetails(account.username, data => {
        setUser(data.find(e => e.appName === 'COMPANY_STUDENT_APP'));
      });
      console.log(
        'Exiting with local storage value:',
        localStorage.getItem(LS_ID_TOKEN)
      );
    }
  }, [isAuthenticated, inProgress, account]);

  const login = () => {
    console.log('Callling login()');
    instance.loginRedirect(loginRequest);
  };

  const logout = (onSuccess = noop, onError = noop) => {
    localStorage.removeItem(LS_USER_ID);
    localStorage.removeItem(LS_ID_TOKEN);
    localStorage.clear();
    instance
      .logoutRedirect()
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onError();
      })
      .finally(() => {});
  };

  const contextValue = {
    idToken,
    accessToken,
    isAuthenticated,
    inProgress,
    login,
    logout,
    fetchTokensIfAuthenticated,
    user,
    company: user?.company || {},
    loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
