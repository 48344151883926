import moment from 'moment';
import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils/helpers';
import {
  addUsersApi,
  deleteUsersApi,
  editUsersApi,
  getUsersApi,
} from 'utils/constants/apis';
import {
  DEFAULT_ERROR_MESSAGE,
  paginationState,
  DEFAULT_PAGINATION_COUNT,
} from 'utils/constants/app';
import axiosInstance from 'utils/helpers/axios';
import qs from 'query-string';

const ManageUsersContext = createContext({
  loading: false,
  users: paginationState,
  getUsers: noop,
  addUsers: noop,
  editUsers: noop,
  deleteUsers: noop,
});

export const useManageUsers = () => useContext(ManageUsersContext);

export const ManageUsersProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(paginationState);
  const { enqueueSnackbar } = useSnackbar();

  const getUsers = (payload, onSuccess = noop, onError = noop) => {
    const { page } = payload;
    const params = qs.stringify({
      count: DEFAULT_PAGINATION_COUNT,
      page,
    });
    setLoading(true);
    axiosInstance
      .get(getUsersApi(params))
      .then(data => {
        const result = data.data || [];
        setUsers(o => ({
          ...o,
          ...data,
          data: [...result],
        }));

        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addUsers = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(addUsersApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Added user successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editUsers = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { id, ...rest } = payload;
    axiosInstance
      .put(editUsersApi(id), rest)
      .then(data => {
        enqueueSnackbar(t('Updated user successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteUsers = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { id } = payload;
    axiosInstance
      .delete(deleteUsersApi(id))
      .then(data => {
        enqueueSnackbar(t('Deleted user successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contextValue = {
    loading,
    users,
    getUsers,
    addUsers,
    editUsers,
    deleteUsers,
  };

  return (
    <ManageUsersContext.Provider value={contextValue}>
      {children}
    </ManageUsersContext.Provider>
  );
};

export default ManageUsersProvider;
