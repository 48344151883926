export const palette = {
  mode: 'light',
  primary: {
    light: '#D7D0FF',
    main: '#602A89',
    dark: '#602A89',
    white: '#ffffff',
  },
  success: {
    light: '#78e08f',
    main: '#4cd137',
    dark: '#44bd32',
  },
  info: {
    light: '#1B9CFC',
    main: '#00a8ff',
    dark: '#0097e6',
  },
  warning: {
    light: '#ffd32a',
    main: '#fbc531',
    dark: '#e1b12c',
  },
  error: {
    light: '#ff5e57',
    main: '#ff3f34',
    dark: '#EA2027',
  },
  action: {
    selected: '#FF8600',
  },
  table: {
    main: '#D0C8FC',
    header: '#D0C8FC',
    headingText: '#27187E',
    titleCell: '#EAE6FF',
    border: '#D4D0D0',
  },
  form: {
    main: '#EAE6FF',
    contrastText: '#27187E',
    icon: '#938CBF',
    successText: '#17AF38',
  },
  appBar: {
    main: '#FFFFFF',
  },
  reactTable: {
    main: '#fff',
    border: '#F0F0F0',
    highLight: '#999999',
  },
};

export const background = {
  paper: '#FFFFFF',
  default: '#fff',
};

const fontWeightLight = 400;
const fontWeightRegular = 500;
const fontWeightMedium = 700;
const fontWeightBold = 900;

const shadows = [
  'none',
  'rgba(71, 88, 114, 0.1) 0px 2px 6px',
  'rgb(221 230 237 / 40%) 0px 16px 32px 0px',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
  '0 6px 20px 0 rgba(26,24,30,.16)',
];

let themeObj = {
  shadows: shadows,
  palette,
  spacing: factor => `${0.25 * factor}rem`,
  typography: {
    htmlFontSize: 25,
    fontFamily: `'Poppins', sans-serif`,
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {},
    h2: {},
    h3: {
      // fontSize: '2rem',
      // fontWeight: fontWeightBold,
    },
    h4: {
      // fontSize: '1.5rem',
      // fontWeight: fontWeightMedium,
    },
    h5: {
      // fontSize: '1.25rem',
      // fontWeight: fontWeightMedium,
    },
    h6: {
      // fontSize: '1rem',
      // fontWeight: fontWeightMedium,
    },
    body1: {
      // fontWeight: fontWeightRegular,
    },
    body2: {},
    button: {
      // fontWeight: fontWeightRegular,
      textTransform: 'initial',
    },
  },
  components: {
    MuiCard: {
      // styleOverrides: {
      //   root: ({ theme }) => ({
      //     borderRadius: theme.spacing(4),
      //   }),
      // },
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.h6,
        }),
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'link' },
          style: {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: theme => theme.palette.info.main,
          },
        },
      ],
    },
    MuiListItemButton: {
      styleOverrides: {
        root: theme => ({}),
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          // fontSize: '0.7rem',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableElevation: true,
        disableFocusRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.square': {
            borderRadius: theme.spacing(4),
          },
        }),
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          display: 'inline-flex',
          flexWrap: 'wrap',
        },
        grouped: ({ theme }) => ({
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }),
        groupedContained: ({ theme }) => ({
          marginRight: `${theme.spacing(4)} !important`,
          borderRadius: `${theme.spacing(8)} !important`,
          border: '0 !important',
        }),
        groupedTextHorizontal: {},
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: ({ theme }) => ({
          // borderRadius: theme.spacing(4),
          boxShadow: theme.shadows[3],
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingRight: 2,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.dark,
          fontSize: '1.5rem',
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          '>div:first-child>div:first-child': {
            display: 'none',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: '0.42rem !important',
          paddingBottom: '0.42rem !important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '0px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #F0F0F0',
        },
      },
    },
  },
};

export const theme = themeObj;
