import moment from 'moment';
import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  addEmployeeApi,
  deleteEmployeeApi,
  editEmployeeApi,
  getEmployeesApi,
} from 'utils/constants/apis';
import {
  DEFAULT_ERROR_MESSAGE,
  paginationState,
  DEFAULT_PAGINATION_COUNT,
} from 'utils/constants/app';
import axiosInstance from 'utils/helpers/axios';
import qs from 'query-string';

const EmployeesContext = createContext({
  loading: false,
  employees: paginationState,
  getEmployees: noop,
  addEmployee: noop,
  editEmployee: noop,
  deleteEmployee: noop,
});

export const useEmployees = () => useContext(EmployeesContext);

export const EmployeesProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState(paginationState);
  const { enqueueSnackbar } = useSnackbar();

  const getEmployees = (payload, onSuccess = noop, onError = noop) => {
    const { page } = payload;
    const params = qs.stringify({
      count: DEFAULT_PAGINATION_COUNT,
      page,
    });
    setLoading(true);
    axiosInstance
      .get(getEmployeesApi(params))
      .then(data => {
        const result = data.data || [];
        setEmployees(o => ({
          ...o,
          ...data,
          data: [...result],
        }));

        onSuccess(data);
      })
      .catch(err => {
        // enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
        //   variant: 'error',
        // });
        // onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addEmployee = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    axiosInstance
      .post(addEmployeeApi(), payload)
      .then(data => {
        enqueueSnackbar(t('Added employee successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editEmployee = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { id, ...rest } = payload;
    axiosInstance
      .put(editEmployeeApi(id), rest)
      .then(data => {
        enqueueSnackbar(t('Updated employee successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteEmployee = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const { id } = payload;
    axiosInstance
      .delete(deleteEmployeeApi(id))
      .then(data => {
        enqueueSnackbar(t('Deleted employee successfully'), {
          variant: 'success',
        });
        onSuccess(data);
      })
      .catch(err => {
        enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
          variant: 'error',
        });
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contextValue = {
    loading,
    employees,
    getEmployees,
    addEmployee,
    editEmployee,
    deleteEmployee,
  };

  return (
    <EmployeesContext.Provider value={contextValue}>
      {children}
    </EmployeesContext.Provider>
  );
};

export default EmployeesProvider;
