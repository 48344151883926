import React, { useState } from 'react';
import { DEFAULT_ERROR_MESSAGE, paginationState } from 'utils/constants/app';
import { noop } from 'utils/helpers';
import { useSnackbar } from 'notistack';
import { getUserCompanyApi } from 'utils/constants/apis';
import axiosInstance from 'utils/helpers/axios';

export const useUserData = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getUserCompanyDetails = (
    email = '',
    onSuccess = noop,
    onError = noop
  ) => {
    setLoading(true);
    axiosInstance
      .get(getUserCompanyApi(email))
      .then(data => {
        onSuccess(data);
      })
      .catch(err => {
        console.log('qq 🚀 ~ useUserData ~ err:', err);
        onError(err);
        enqueueSnackbar(DEFAULT_ERROR_MESSAGE, {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    getUserCompanyDetails,
  };
};
