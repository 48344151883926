import { DEFAULT_LANGUAGE } from 'utils/constants/app';
import { LS_LANGUAGE } from 'utils/constants/localStorage';

export const noop = () => {};

export const arrayIndexSequencePicker = (index, length = 1) => index % length;

export const getLanguageCode = () => {
  return localStorage.getItem(LS_LANGUAGE) || DEFAULT_LANGUAGE;
};
